<template>
  <div>
    <v-container class="container px-0">
      <v-row
        class="title-row"
        :class="`${
          $vuetify.breakpoint.mdAndDown ? 'flex-column flex-nowrap' : ''
        }`"
      >
        <v-col
          class="d-flex align-center"
          :style="
            $vuetify.breakpoint.width <= 1400
              ? ''
              : 'min-width: calc(100% - 482px); max-width: calc(100% - 482px)'
          "
        >
          <h4 class="font-weight-light">{{ $t('heading.hosting.emailForwarders.title') }}</h4>
          <v-select
            :label="$t('heading.hosting.emailDomain.title')"
            class="ml-2 dns-zone"
            outlined
            style="max-width: calc(100% - 102px)"
            :hide-details="true"
            v-model="selectedEmailDomain"
            :items="emailDomains.items"
            item-text="domain"
            item-value="domain"
            :loading="emailDomains.loading"
            @change="reloadEmailForwarders"
            return-object
            :menu-props="{
              offsetY: true,
              nudgeBottom: '8px',
            }"
          />
        </v-col>

        <v-col
          class="page-title__filter-controls justify-end"
          :class="[`${$vuetify.breakpoint.mobile ? 'pb-3' : ''}`]"
        >
          <template v-if="!emailForwarders.loading">
            <data-iterator-filter-controls
              v-if="emailForwarders.items.length"
              :keys="keys"
              :showSearchTerm="true"
              :searchTerm="searchTerm"
              :fullWidthSearch="false"
              :fullWidthLg="false"
              :fullWidthMd="true"
              @update:sortBy="changeSortBy"
              @update:sortDesc="handleSortDescChange"
              @update:searchTerm="handleSearchTermChange"
            />

            <v-btn
              v-if="emailForwarders.items.length"
              :large="!$vuetify.breakpoint.smAndDown"
              elevation="0"
              color="primary"
              class="p-2 add-new-button page-title__add-button"
              @click="showAddForwarderModal"
              :loading="addButtonLoading"
            >
              <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
              >$plus
              </v-icon
              >
              {{ $vuetify.breakpoint.mobile ? "" : $t('button.emailForwarder.add') }}
            </v-btn>
          </template>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-3 px-0">
      <v-row>
        <v-col cols="12">
          <div :class="checkedItems.length > 0 ? 'pb-15' : ''">
            <hosting-table
              :headers="headers"
              :items="emailForwarders.items"
              :itemsLoading="emailForwarders.loading"
              :itemsPerPage="itemsPerPage"
              :itemsTotal="itemsTotal"
              :page="page"
              :possibleItemsPerPage="[[8, 16, 24]]"
              :searchTerm="searchTerm"
              :sortBy="sortBy"
              :sortDesc="sortDesc"
              @clearFilters="searchTerm = ''"
              @update:check-all="handleSelectAll"
              @update:checked-items="handleItemCheck"
              @update:itemsPerPage="changePerPage"
              @update:page="changePage"
              @update:sortBy="changeSortBy"
              @update:sortDesc="changeSortDesc"
              :itemClass="itemClass"
            >
              <template v-slot:no-data>
                <TableFullScreenMessage
                  :title="$t('message.emptyTable.emailForwarders.title')"
                  :desc="$t('message.emptyTable.emailForwarders.description')"
                >
                  <template v-slot:image>
                    <hosting-email-forwarders-illustration/>
                  </template>
                  <template v-slot:action>
                    <v-btn
                      :large="!$vuetify.breakpoint.smAndDown"
                      :small="$vuetify.breakpoint.smAndDown"
                      elevation="0"
                      color="primary"
                      class="p-2 ml-0 add-new-button"
                      @click="showAddForwarderModal"
                      :loading="addButtonLoading"
                      rounded
                    >
                      <v-icon :class="$vuetify.breakpoint.mobile ? 'mr-0' : ''"
                      >$plus
                      </v-icon
                      >
                      {{ $vuetify.breakpoint.mobile ? "" : $t('button.emailForwarder.add') }}
                    </v-btn>
                  </template>
                </TableFullScreenMessage>
              </template>
              <template v-slot:actions="item">
                <v-tooltip
                  transition="custom-tooltip"
                  open-delay="150"
                  bottom
                  z-index="99"
                  offset-overflow
                  nudge-bottom="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-on="on"
                      v-bind="attrs"
                      icon
                      small
                      @click="showDeleteForwarderModal(item)"
                    >
                      <v-icon>$thrash</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('button.emailForwarder.delete') }}</span>
                </v-tooltip>
              </template>

              <template v-slot:mobile="{ item, headers }">
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[0])"
                  >
                    {{ $t('table.header.email') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.email }}</span>
                  </div>
                </div>
                <div class="mobile-table-item__row">
                  <div
                    class="mobile-table-item__header"
                    @click="$emit('update:sortBy', headers[1])"
                  >
                    {{ $t('table.header.forwardTo') }}
                  </div>
                  <div class="mobile-table-item__value">
                    <span class="bold p-3">{{ item.forward_to }}</span>
                  </div>
                </div>
              </template>
              <template v-slot:updated_at="item">
                <span>{{ item.updated_at_text }}</span>
              </template>
            </hosting-table>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
      transition="custom-dialog-transition"
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()"/>
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
        ref="modal"
      />
    </v-dialog>
  </div>
</template>

<script>
import Api from "../../apis/Api";

import BasicModal from "@/components/modal/BasicModal";
import DataIteratorFilterControls from "../../components/dataIterator/DataIteratorFilterControls.vue";
import HostingTable from "../../components/dataIterator/tables/HostingTable.vue";
import TableFullScreenMessage from "../../components/dataIterator/tables/TableFullScreenMessage.vue";
import HostingEmailForwardersIllustration
  from "../../components/illustrations/hosting/hosting-email-forwarders-illustration.vue";
import ActionModalMixin from "@/mixins/ActionModalMixin";
import DataIteratorPageMixin from "../../mixins/DataIteratorPageMixin";
import CustomTablePageMixin from "../../mixins/CustomTablePageMixin";
import moment from "moment";
import EmailForwardersActionsMixin from "@/mixins/EmailForwardersActionsMixin";

export default {
  components: {
    BasicModal,
    DataIteratorFilterControls,
    HostingTable,
    TableFullScreenMessage,
    HostingEmailForwardersIllustration,
  },
  mixins: [ActionModalMixin, DataIteratorPageMixin, CustomTablePageMixin, EmailForwardersActionsMixin],
  data() {
    return {
      isServerProvided: false,
      sortBy: "updated_at",
      sortDesc: false,
      searchTerm: "",
      headers: [
        {
          text: 'email',
          value: "email",
          sortable: true,
        },
        {
          text: 'forwardTo',
          value: "forward_to",
          sortable: true,
        },
        {
          text: 'created',
          value: "updated_at",
          sortable: true,
        },
        {
          text: 'actions',
          value: "actions",
        },
      ],
      page: 1,
      addButtonLoading: false,
      itemsPerPage: 8,
      modalRender: 0,
      modalOptions: {open: false},
      highlightItem: {},
      selectedEmailDomain: null,
      emailDomains: {
        loading: false,
        items: []
      },
      emailForwarders: {
        loading: false,
        items: []
      },
    };
  },
  props: {
    service: Object
  },
  watch: {
    highlightItem: function (newValue) {
      if (!newValue) return;
      setTimeout(() => {
        this.highlightItem = {};
      }, 1000);
    },
    service: function () {
      this.reloadEmailDomains();
    },
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
  computed: {
    itemsTotal: function () {
      return this.emailForwarders.items.length;
    },
  },
  methods: {
    reloadEmailDomains() {
      this.emailDomains.loading = true;
      Api.cached()
        .get(`/services/${this.service.id}/email/domains`)
        .then((response) => {
          this.emailDomains.items = response.data.data;
          if (response.data.data.length) {
            this.selectedEmailDomain = response.data.data[0];
            this.reloadEmailForwarders();
          } else {
            this.emailForwarders.items = [];
            this.emailForwarders.loading = false;
          }
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.emailDomains.loading = false;
        });
    },
    reloadEmailForwarders(emailDomain = null) {
      if (emailDomain !== null) {
        this.selectedEmailDomain = emailDomain;
      }
      this.emailForwarders.loading = true;
      if (this.selectedEmailDomain === null) {
        this.emailForwarders.loading = false;
        return;
      }

      Api.get(`/services/${this.service.id}/email/domains/${this.selectedEmailDomain.domain}/forwarders`)
        .then((response) => {
          this.emailForwarders.items = response.data.data.map((item) => {
            item.updated_at_text = item.updated_at
              ? moment(item.updated_at).fromNow()
              : "-";
            return item;
          });
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.emailForwarders.loading = false;
        });
    },
    itemClass(item) {
      if (
        this.highlightItem.email &&
        item.email == this.highlightItem.email &&
        item.forward_to == this.highlightItem.forward_to
      ) {
        return "highlight";
      }
    },
  },
  mounted() {
    if (this.service) {
      this.reloadEmailDomains();
    }
  },
};
</script>

<style scoped lang="scss">
// .title-row {
//   height: 70px;
// }

.v-window::v-deep {
  // overflow: visible !important;
  background-color: transparent;

  padding: 8px 32px 16px 32px;
  margin: -8px -32px -16px -32px;
  width: calc(100% + 64px);
  max-width: unset;

  .v-window-item,
  .v-window__container {
    overflow: visible !important;
  }
}

.v-tab {
  min-width: unset;
  max-height: 46px;
}

.v-tabs::v-deep {
  max-height: 46px;
}

.v-input.v-select::v-deep {
  fieldset {
    border: none;
  }

  .v-select__selection {
    padding: 0;
    font-size: "$font-size-h3";
    line-height: "$line-height-h3";
    font-weight: "$font-weight-bold";
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden !important;
    @media (min-width: 1401px) {
      max-width: 99%;
    }
  }

  &.v-select .v-select__slot {
    width: unset;
  }
}
</style>
